import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import './communication-center-view.scss';
import ErrorPage from '@/components/sections/profile-error/error-page';

const className = 'communication-center';

const CommunicationCenterView = () => {
    const appConfig = new AppConfigurationService();
    const [loading, setIsLoading] = useState(true);
    const [showErrorPage, handleShowErrorPage] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const renderContent = () => {
        if (showErrorPage) {
            return <ErrorPage />;
        } else if (!loading) {
            return (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}-view`}
                >
                    <Breadcrumbs
                        currentPage={'Communication Preferences'}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    ></Breadcrumbs>
                    <hr className="hr-line" aria-hidden={true} />
                </div>
            );
        } else {
            return <ActivityIndicator className={'full-height'} />;
        }
    };

    return <>{renderContent()}</>;
};
export default CommunicationCenterView;

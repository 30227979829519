import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    BRAND,
    DEFAULT_GDPR_COOKIE_VALUE,
    EU_COUNTRIES,
    IMG_COUNTRIES,
    PERSON_ICON_COUNTRIES,
    SA_COUNTRIES,
    SYNDICATED_HEADER_COUNTRIES,
} from '@constants';
import { findPathByAlias, RoutesList } from '@routes/routesList';
import AuthenticationService from '@services/authentication-service/authentication-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { scriptService } from '@services/script-service/script-service';
import ServerContext from '@contexts/serverContext';
import NotificationProvider from '@contexts/notificationContext';
import {
    EuHeader,
    Footer,
    GdprBanner,
    LincolnNAFooter,
    LincolnNAHeader,
} from '@sections/index';
import { NotificationBanner } from '@sections/notification-banner/notification-banner';
import { remapDragonflySyndicatedHeaderLinks } from '@utils/dragonfly-syndicated-header-links';
import './App.scss';
import ModalProvider from './contexts/modalContext';
import HelpRouterContainer from '@sections/help-router/help-router-container';
import serverSideService from '@services/server-side-service/server-side-service';
import SideDrawerProvider from '@contexts/sideDrawerContext';

interface Props {
    serverContext?: any;
    serverSideService: any;
}

function App(props: Props) {
    const currentAppConfiguration = new AppConfigurationService();
    const [brand, language, country] = [
        props.serverContext
            ? props.serverContext.brand
            : currentAppConfiguration.getAppConfiguration().brand,
        props.serverContext
            ? props.serverContext.currentLanguageRegionCode
            : currentAppConfiguration.getAppConfiguration().languageRegionCode,
        props.serverContext
            ? props.serverContext.currentRegionCode
            : currentAppConfiguration.getAppConfiguration().countryCode,
    ];
    const navigate = useNavigate();
    const isEu: boolean = EU_COUNTRIES.includes(country);
    const isNotNa: boolean = PERSON_ICON_COUNTRIES.includes(country);
    const authenticationService = new AuthenticationService();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const context = props.serverSideService.isServerSide()
        ? props.serverContext
        : (window as any).SERVER_CONTEXT;
    const location = useLocation();
    const fmaState: string = authenticationService.getState();
    const isOnSignInCredentialsPage: boolean = location.pathname.includes(
        '/signin-credentials'
    );

    const isOnPreferredDealerPage: boolean =
        location.pathname.includes('/preferred-dealer');
    const fullPathname = location.pathname + (location.search || '');
    const isOnIDPSignInPage: boolean =
        location.pathname.includes('signin-credentials');
    const isWebview: boolean = location.search.includes('webview');
    const showHelpRouter =
        !isOnIDPSignInPage && !isOnPreferredDealerPage && !isWebview;

    const syndicatedHeaderLoaded = () => {
        return SYNDICATED_HEADER_COUNTRIES.includes(context.currentRegionCode);
    };

    const hideHeaderAndFooter = (): void => {
        const headerAndFooter: NodeListOf<Element> = document.querySelectorAll(
            'header, footer, .eu-header, .eu-footer, #global-ux, .footer, .fgx-pantry-bootstrap'
        );
        headerAndFooter.forEach((element: HTMLElement) => {
            if (element && element.style.display !== 'none') {
                element.style.display = 'none';
            }
        });
    };

    const showHeaderAndFooter = (): void => {
        const headerAndFooter: NodeListOf<Element> = document.querySelectorAll(
            'header, footer, .eu-header, .eu-footer, #global-ux, .footer, .fgx-pantry-bootstrap'
        );
        headerAndFooter.forEach((element: HTMLElement) => {
            if (element && element.style.display === 'none') {
                element.style.display = 'block';
            }
        });
    };

    useEffect(() => {
        if (
            location &&
            ['mx', ...IMG_COUNTRIES, ...SA_COUNTRIES].includes(
                currentAppConfiguration.getAppConfiguration().countryCode
            )
        ) {
            remapDragonflySyndicatedHeaderLinks(
                language,
                currentAppConfiguration.currentDomain,
                currentAppConfiguration.getPathname(),
                location.search
            );
        }
    }, [fullPathname, location.search]);

    useEffect(() => {
        const syndicatedSignOutHandler = () => {
            sessionStorage.clear();
        };
        const syndicatedSignOut = document.querySelector(
            'a[href*="#$userSignOut"]'
        ) as HTMLAnchorElement;

        scriptService.setMouseflowScript();
        scriptService.loadAnalyticsScript();
        scriptService.loadConnectedServicesScript();
        if (country === 'us') {
            scriptService.loadOneTrustCookieBanner();
        }
        if (country === 'ca') {
            scriptService.loadEvidonCookieBanner();
        }
        if (syndicatedHeaderLoaded()) {
            syndicatedSignOut &&
                syndicatedSignOut.addEventListener(
                    'click',
                    syndicatedSignOutHandler
                );
        }

        scriptService.unloadFMA();
        scriptService.loadFMA();

        return () => {
            syndicatedSignOut &&
                syndicatedSignOut.removeEventListener(
                    'click',
                    syndicatedSignOutHandler
                );
        };
    }, [language]);

    function addDashboardPath() {
        return window.location.href.endsWith('/')
            ? window.location.href.concat('account-dashboard')
            : window.location.href.concat('/account-dashboard');
    }

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((authenticated) => {
            const pathName = location.pathname.endsWith('/')
                ? location.pathname
                : location.pathname + '/';

            !authenticationService.getFmaState() &&
                authenticationService.setFmaState();

            authenticationService.setFmaRedirectUrl(
                pathName ===
                    currentAppConfiguration.getAppConfiguration().root ||
                    location.pathname === '/'
                    ? addDashboardPath()
                    : window.location.href
            );

            const newCurrentAppConfiguration =
                currentAppConfiguration.getAppConfiguration();

            const simplifiedUrlPathname = `/${newCurrentAppConfiguration.brand}/${newCurrentAppConfiguration.languageRegionCode}/`;

            if (authenticated) {
                const pathnameWithSlash = window.location.pathname.endsWith('/')
                    ? window.location.pathname
                    : window.location.pathname + '/';

                if (pathnameWithSlash === simplifiedUrlPathname) {
                    window.location.pathname.endsWith('/')
                        ? (window.location.pathname =
                              window.location.pathname + 'account-dashboard')
                        : (window.location.pathname =
                              window.location.pathname + '/account-dashboard');
                }

                if (!sessionStorage.getItem('postLoginNavComplete')) {
                    setIsAuthenticated(true);

                    if (authenticationService.getFmaState()) {
                        const state = authenticationService.getFmaState();
                        navigate(state?.startsWith('/') ? state : `/${state}`);
                    } else if (!authenticationService.getFmaState()) {
                        if (location.pathname === '/')
                            navigate(findPathByAlias('AccountDashboardView'));
                    }

                    sessionStorage.setItem('postLoginNavComplete', 'true');
                }
            }
        });
    }, [location]);

    useEffect(() => {
        const portal = document.getElementById('portal');

        if (brand && portal) {
            portal.className = `brand-${brand}`;
            portal.setAttribute('data-brand', brand);
        }
    }, []);

    useEffect(() => {
        const sideDrawerPortal = document.getElementById('side-drawer-portal');

        if (brand && sideDrawerPortal) {
            sideDrawerPortal.className = `brand-${brand}`;
            sideDrawerPortal.setAttribute('data-brand', brand);
        }
    }, []);

    useEffect(() => {
        if (isOnSignInCredentialsPage || isWebview) {
            hideHeaderAndFooter();
        } else if (!isOnSignInCredentialsPage && !isWebview) {
            showHeaderAndFooter();
        }
    }, [location.pathname]);

    useEffect(() => {
        if (fmaState && fmaState.includes('webview')) {
            hideHeaderAndFooter();
        }
    }, [fmaState]);

    useEffect(() => {
        if (serverSideService.isClientSide() && isEu) {
            (window as any).digitaldata = {
                page: { country: `ford-${country}` },
                user: {
                    optInStatus: DEFAULT_GDPR_COOKIE_VALUE,
                },
            };
        }
    }, []);

    const getBrandHeader = () => {
        if (isOnSignInCredentialsPage || isWebview) return null;

        if (syndicatedHeaderLoaded()) return <></>;

        if (EU_COUNTRIES.includes(country)) return <EuHeader />;

        if (brand === BRAND.lincoln.LONG_NAME) return <LincolnNAHeader />;

        return null;
    };
    const getBrandFooter = () => {
        if (isOnSignInCredentialsPage || isWebview) return null;
        if (syndicatedHeaderLoaded()) return <></>;
        let footerRenderer = <Footer />;
        if (brand === BRAND.lincoln.LONG_NAME) {
            footerRenderer = <LincolnNAFooter />;
        }
        return footerRenderer;
    };
    const loadGDPRBanner = (): boolean => {
        const isSweden =
            currentAppConfiguration.getAppConfiguration().countryCode === 'se';
        return EU_COUNTRIES.includes(country) && !isSweden;
    };

    const changeImgSyndicatedHeaderClass = (): void => {
        const imgSyndicatedHeader =
            document.querySelector('#global-ux .header');
        const brandLogoContainer = document.querySelector(
            '.navigation_item-center'
        );
        const isProd =
            process.env.REACT_APP_ACTIVE_PROFILE &&
            process.env.REACT_APP_ACTIVE_PROFILE === 'prod';

        if (imgSyndicatedHeader) {
            imgSyndicatedHeader.classList.replace('header', 'fixed-header');

            if (
                !brandLogoContainer.contains(
                    document.querySelector('.logoImg')
                ) &&
                !isProd &&
                ['vn', 'th'].includes(country)
            ) {
                const country =
                    currentAppConfiguration.getAppConfiguration().countryCode;
                const languageRegionCode = currentAppConfiguration
                    .getAppConfiguration()
                    .languageRegionCode.replace('-', '_');
                const brandLogo = document.createElement('a');

                brandLogo.href = `/content/ford/${country}/${languageRegionCode}/dxdhome.html`;
                brandLogo.className = 'logoImg';

                brandLogoContainer.appendChild(brandLogo);
            }
        }
    };

    useEffect(() => {
        IMG_COUNTRIES.includes(country) && changeImgSyndicatedHeaderClass();
    }, []);

    return (
        <>
            <ServerContext.Provider value={context}>
                <div
                    className={`brand-${brand} ${isEu ? 'eu' : ''} ${
                        isNotNa ? 'not-na' : 'na'
                    }`}
                    data-brand={brand}
                    data-testid="app"
                >
                    <ModalProvider>
                        <SideDrawerProvider>
                            {getBrandHeader()}

                            {showHelpRouter && (
                                <HelpRouterContainer
                                    language={language}
                                    country={country}
                                />
                            )}

                            <NotificationProvider>
                                <NotificationBanner type={null} />
                                <div className="main-overlay" />
                                <main className="app">
                                    <RoutesList />
                                </main>
                            </NotificationProvider>
                            {getBrandFooter()}
                            {loadGDPRBanner() && <GdprBanner />}
                        </SideDrawerProvider>
                    </ModalProvider>
                </div>
            </ServerContext.Provider>
        </>
    );
}

export default App;

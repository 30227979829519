import React, { useEffect, useState } from 'react';
import SecondaryButton from '@common/secondary-button/secondary-button';
import TertiaryButton from '@/components/common/tertiary-button/tertiary-button';
import {
    OmsOrderDetailsResponse,
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { OrderDetailsContent } from '../hooks/use-order-details-content';
import { ProfileWithVehiclesResponse } from '@/models/profile-with-vehicles';
import OrderTotals from '@views/order-details-view/components/order-totals/order-totals';
import OrderDetailsItem from '@views/order-details-view/components/order-details-item/order-details-item';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_ORDER_STATUS_MAP,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { useLocation, useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';
import { useAnalytics } from '@/hooks/use-analytics';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { LinkTargetValues } from '@/constants';

const className = 'order-details-component';

interface Props {
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    orderDetailsData: OmsOrderDetailsResponse;
    ordersFinishedLoading: boolean;
    authenticated: ProfileWithVehiclesResponse;
}

const OrderDetailsComponent = (props: Props) => {
    const {
        orderDetailsContent,
        orderDetailsData,
        ordersFinishedLoading,
        orderStatusContent,
    } = props;
    const [fireAnalytics] = useAnalytics();
    const location = useLocation();
    const date = new Date(orderDetailsData?.orderDate);
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [orderDetailsItems, setOrderDetailsItems] =
        useState<OrderLineDetails[]>(null);
    const languageRegionCode = appConfig.getLanguageRegionCode().toLowerCase();
    const formattedDate = date.toLocaleDateString(languageRegionCode, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const showStartReturnButton = orderDetailsData?.orderLine?.some(
        (item) => item.isStorefrontReturnable === 'Y'
    );
    const deliveryMethod: string =
        orderDetailsData?.orderLine[0]?.deliveryMethod;
    const navigate = useNavigate();

    const stringifiedOrderData = orderDetailsData;

    const returnPolicyPageUrl =
        'https://www.nvc-pp.ford.com/accessories/return-policy';

    const sortItems = (): OrderLineDetails[] => {
        let finalItems: OrderLineDetails[] = [];
        const deliveryMethodTransformed =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                const filteredList = orderDetailsData.orderLine.filter((item) =>
                    statusOption.options.includes(item.status.toLowerCase())
                );

                if (filteredList.length) {
                    finalItems = [...finalItems, ...filteredList];
                }
            }
        );

        return finalItems;
    };

    useEffect(() => {
        if (orderDetailsData && orderDetailsData.orderLine.length) {
            setOrderDetailsItems(sortItems());
        }
    }, [orderDetailsData]);

    return (
        <>
            {ordersFinishedLoading && orderDetailsContent.billingAddress && (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}`}
                >
                    <h1
                        className={
                            props.authenticated
                                ? `${className}-title`
                                : `${className}-title-unauthenticated`
                        }
                        data-testid={`${className}-title`}
                    >
                        {orderDetailsContent.order}{' '}
                        {orderDetailsData?.orderNo ||
                            '#' + location.pathname.split('FAE:')[1]}
                    </h1>

                    {orderDetailsData && (
                        <>
                            <div className={`${className}-details`}>
                                <p className={`${className}-order-date`}>
                                    {orderDetailsContent.orderDate}
                                </p>
                                <p className={`${className}-date`}>
                                    {formattedDate}
                                </p>
                                {orderDetailsData.personInfoBillTo ||
                                orderDetailsData.personInfoShipTo ? (
                                    <div
                                        className={`${className}-address-container`}
                                        data-testid={`${className}-address-container`}
                                    >
                                        <div
                                            className={`${className}-billing-address-container`}
                                        >
                                            <p
                                                className={`${className}-billing-address-title`}
                                            >
                                                {
                                                    orderDetailsContent.billingAddress
                                                }
                                            </p>
                                            <p
                                                className={`${className}-billing-address`}
                                                data-testid={`${className}-billing-address-name`}
                                            >
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo
                                                        ?.firstName
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo
                                                        ?.lastName
                                                }
                                            </p>
                                            <p
                                                className={`${className}-billing-address`}
                                                data-testid={`${className}-billing-address`}
                                            >
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo
                                                        ?.addressLine1
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo
                                                        ?.addressLine2
                                                }{' '}
                                                {', '}
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo?.city
                                                }
                                                {', '}
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo?.state
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoBillTo
                                                        ?.zipCode
                                                }
                                            </p>
                                        </div>
                                        <div
                                            className={`${className}-shipping-address-container`}
                                        >
                                            <p
                                                className={`${className}-shipping-address-title`}
                                            >
                                                {
                                                    orderDetailsContent.shippingAddress
                                                }
                                            </p>
                                            <p
                                                className={`${className}-shipping-address`}
                                                data-testid={`${className}-shipping-address-name`}
                                            >
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo
                                                        ?.firstName
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo
                                                        ?.lastName
                                                }
                                            </p>
                                            <p
                                                className={`${className}-shipping-address`}
                                                data-testid={`${className}-shipping-address`}
                                            >
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo
                                                        ?.addressLine1
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo
                                                        ?.addressLine2
                                                }{' '}
                                                {', '}
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo?.city
                                                }
                                                {', '}
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo?.state
                                                }{' '}
                                                {
                                                    orderDetailsData
                                                        .personInfoShipTo
                                                        ?.zipCode
                                                }{' '}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                {showStartReturnButton ? (
                                    <div
                                        className={`${className}-return-buttons-container`}
                                    >
                                        <SecondaryButton
                                            dataTestId="start-return-button"
                                            aria-label={
                                                orderDetailsContent.startReturn
                                            }
                                            onClick={() => {
                                                navigate(
                                                    findPathByAlias(
                                                        'CreateReturnView'
                                                    ),
                                                    {
                                                        state: {
                                                            orderDetails:
                                                                stringifiedOrderData,
                                                            guestOrder:
                                                                location.state
                                                                    ?.guestOrder,
                                                        },
                                                    }
                                                );
                                            }}
                                        >
                                            {orderDetailsContent.startReturn}
                                        </SecondaryButton>
                                        <TertiaryButton
                                            labelText={
                                                orderDetailsContent.returnPolicy
                                            }
                                            ariaLabel={
                                                orderDetailsContent.returnPolicy
                                            }
                                            internal={false}
                                            link={returnPolicyPageUrl}
                                            linkTarget={LinkTargetValues.BLANK}
                                            onClick={() => {
                                                fireAnalytics(
                                                    'orderDetailsReturnsPolicyOnclickEvent'
                                                );
                                            }}
                                        ></TertiaryButton>
                                    </div>
                                ) : (
                                    <div
                                        className={`${className}-return-policy-container`}
                                    >
                                        <TertiaryButton
                                            labelText={
                                                orderDetailsContent.returnPolicy
                                            }
                                            ariaLabel={
                                                orderDetailsContent.returnPolicy
                                            }
                                            internal={false}
                                            link={returnPolicyPageUrl}
                                            linkTarget={LinkTargetValues.BLANK}
                                            onClick={() => {
                                                fireAnalytics(
                                                    'orderDetailsReturnsPolicyOnclickEvent'
                                                );
                                            }}
                                        ></TertiaryButton>
                                    </div>
                                )}
                            </div>
                            <hr className="hr-line" aria-hidden={true} />
                            {orderStatusContent &&
                                orderDetailsItems?.map(
                                    (item: OrderLineDetails) => {
                                        return (
                                            <OrderDetailsItem
                                                key={item.orderLineKey}
                                                itemData={item}
                                                orderDetailsContent={
                                                    orderDetailsContent
                                                }
                                                orderStatusContent={
                                                    orderStatusContent
                                                }
                                            />
                                        );
                                    }
                                )}
                            <OrderTotals
                                orderDetailsContent={orderDetailsContent}
                                orderDetailsData={orderDetailsData}
                            />
                        </>
                    )}
                </div>
            )}
            {!ordersFinishedLoading ? (
                <ActivityIndicator className={'full-height'} />
            ) : null}
        </>
    );
};

export default OrderDetailsComponent;

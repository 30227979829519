import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import './active-orders-carousel.scss';
import { SecondaryButton } from '@common/index';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import {
    OrderHistoryOrder,
    OrderLine,
} from '@services/oms-service/oms-service';
import ActiveOrdersCard from '@sections/active-orders-carousel/active-orders-card/active-orders-card';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import CarouselIndicator, {
    Direction,
} from '@sections/account-portal/components/vehicle-carousel/carousel-indicator/carousel-indicator';
import { useOrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import ActiveVehicleOrderCard from '@sections/active-orders-carousel/active-vehicle-order-card/active-vehicle-order-card';

export interface ActiveOrdersCarouselProps {
    vehicleCarouselContent: VehicleCarouselContent;
    isMobile: boolean;
    activeOrders: AccessoriesCarouselItem[];
    activeVehiclesOnOrder: OrderedVehiclesItem[];
    appConfig: any;
}

export interface AccessoriesCarouselItem {
    order: OrderHistoryOrder;
    item: OrderLine;
}

const ActiveOrdersCarousel = (props: ActiveOrdersCarouselProps) => {
    const {
        vehicleCarouselContent,
        isMobile,
        activeOrders,
        activeVehiclesOnOrder,
        appConfig,
    }: ActiveOrdersCarouselProps = props;
    const navigate = useNavigate();
    const isNa: boolean = appConfig.getAppConfiguration().fmaRegion === 'na';
    const componentContainerRef = useRef<HTMLDivElement>(null);
    const activeOrdersCarouselRef = useRef<HTMLDivElement>(null);
    const activeOrdersCardRef = useRef<HTMLLIElement>(null);
    const orderStatusContent = useOrderStatusContent();
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const totalCards: number =
        activeOrders?.length + activeVehiclesOnOrder?.length;
    const carouselTransition: React.CSSProperties = {
        transform: `translateX(${cardsPositionX}px)`,
        WebkitTransform: `translateX(${cardsPositionX}px)`,
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden',
        perspective: 1000,
        WebkitPerspective: 1000,
        transition: 'transform 325ms linear',
        WebkitTransition: 'transform 325ms linear',
        willChange: 'transform',
    };

    const handleViewOrderHistoryClick = (): void => {
        navigate(findPathByAlias('OrderHistoryView'));
    };

    const moveToNextCard = (): void => {
        if (currentCardIndex < totalCards - 1) {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    };

    const moveToPreviousCard = (): void => {
        if (currentCardIndex > 0) {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    };

    const updateCardPositions = useCallback(() => {
        setCardsPositionX(
            -currentCardIndex *
                Math.ceil(
                    activeOrdersCardRef.current?.getBoundingClientRect().width +
                        16
                )
        );
    }, [activeOrdersCardRef, currentCardIndex, totalCards]);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentCardIndex, isMobile, updateCardPositions, totalCards]);

    return (
        <div
            className="active-orders-carousel__container"
            data-testid="active-orders-carousel-container"
            ref={componentContainerRef}
        >
            <div
                className="active-orders-carousel__header-container"
                data-testid="active-orders-carousel-header-container"
            >
                <h2
                    className="active-orders-carousel__header-text"
                    data-testid="active-orders-carousel-header-text"
                >
                    {vehicleCarouselContent.activeOrdersHeader}
                </h2>

                {isNa && (
                    <SecondaryButton
                        internal
                        className="view-order-history-link"
                        onClick={handleViewOrderHistoryClick}
                        dataTestId="view-order-history-link"
                        aria-label={
                            vehicleCarouselContent?.viewOrderHistoryAriaLabel
                        }
                    >
                        {vehicleCarouselContent?.viewOrderHistoryCtaText}
                    </SecondaryButton>
                )}
            </div>
            <div className="fmc-carousel-container">
                <div
                    className="active-orders__carousel js-fmc-carousel-container"
                    data-testid="active-orders-carousel"
                    ref={activeOrdersCarouselRef}
                >
                    <ul
                        className="fmc-carousel fmc-cards"
                        style={
                            activeOrdersCardRef
                                ? carouselTransition
                                : {
                                      transform: `translateX(${cardsPositionX}px)`,
                                  }
                        }
                        data-testid="active-orders-carousel-list"
                    >
                        {activeVehiclesOnOrder?.map(
                            (order: OrderedVehiclesItem, index: number) => {
                                return (
                                    <li
                                        key={`order-card-${index + 1}`}
                                        ref={(instance) =>
                                            (activeOrdersCardRef.current =
                                                instance)
                                        }
                                        tabIndex={0}
                                    >
                                        <ActiveVehicleOrderCard
                                            appConfig={appConfig}
                                            order={order}
                                            vehicleCarouselContent={
                                                vehicleCarouselContent
                                            }
                                            isCurrentCard={
                                                currentCardIndex === index
                                            }
                                        />
                                    </li>
                                );
                            }
                        )}
                        {orderStatusContent &&
                            activeOrders?.map(
                                (
                                    item: AccessoriesCarouselItem,
                                    index: number
                                ) => {
                                    const actualIndex =
                                        index +
                                        (activeVehiclesOnOrder?.length || 0) +
                                        1;

                                    return (
                                        <li
                                            key={`order-card-${actualIndex}`}
                                            ref={(instance) =>
                                                (activeOrdersCardRef.current =
                                                    instance)
                                            }
                                            tabIndex={0}
                                        >
                                            <ActiveOrdersCard
                                                order={item.order}
                                                item={item.item}
                                                orderStatusContent={
                                                    orderStatusContent
                                                }
                                                vehicleCarouselContent={
                                                    vehicleCarouselContent
                                                }
                                                isCurrentCard={
                                                    currentCardIndex === index
                                                }
                                            />
                                        </li>
                                    );
                                }
                            )}
                    </ul>
                </div>
                {!isMobile && totalCards > 3 ? (
                    <div
                        className="fmc-carousel-indicator__container"
                        data-testid="active-orders-carousel-indicators-container"
                    >
                        <CarouselIndicator
                            direction={Direction.LEFT}
                            backgroundColor="black"
                            caretColor="white"
                            onClick={moveToPreviousCard}
                            disabled={currentCardIndex === 0}
                            fromCvot
                        />
                        <CarouselIndicator
                            direction={Direction.RIGHT}
                            backgroundColor="black"
                            caretColor="white"
                            onClick={moveToNextCard}
                            disabled={currentCardIndex + 1 === totalCards}
                            fromCvot
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ActiveOrdersCarousel;
